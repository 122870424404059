import React from "react";
import {Fragment, useEffect} from "react";

type RenderProps = {
    pageContext
}

const Redirection: React.FC<RenderProps> = ({ pageContext }) => {
    useEffect(() => {
        location.replace(pageContext.redirectionUrl);
    })

    return (
        <Fragment />
    )
}

export default Redirection;